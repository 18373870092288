<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="showModal"
    >   
        <CForm>
            
            <CRow>
              <CCol md="4">
                <CSelect
                  label="Acción"
                  :value.sync="$v.form.action.$model"
                  :options="actionsOptions"
                />
              </CCol>
              <CCol md="4">
                <CSelect
                  label="Tipo de Puntos"
                  :value.sync="$v.form.type.$model"
                  :options="typesOptions"
                />
              </CCol>
              <CCol md="4">
                <CInput
                  label="Puntos"
                  :lazy="false"
                  :value.sync="$v.form.points.$model"
                  :isValid="checkIfValid('points')"
                  placeholder="500"
                  autocomplete="given-points"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
              </CCol>
            </CRow>
            <CRow style="margin-top: 10px;">
              <CCol md="12">
                <CInput
                  label="Descripción"
                  :lazy="false"
                  :value.sync="$v.form.description.$model"
                  :isValid="checkIfValid('description')"
                  placeholder="Escribe una descripción..."
                  autocomplete="given-description"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
              </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, email, sameAs, helpers } from "vuelidate/lib/validators"
import ws from '../../services/suppliers';
import managers_ws from '../../services/managers';
import cedis_ws from '../../services/cedis';
import store from '../../store'

export default {
  name: 'PointsModal',
  props: {

  },
  data () {
    return {
      showModal: false,
      title: "Editar Puntos",
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      fields: [
        {key: 'name', label: 'Nombre'},
        {key: 'actions', label: '', class: 'actions' }
      ],
      link: '',
      item: false,
      typesOptions:[],
      actionsOptions:[]
    }
  },
  mounted: async function() {
    this.typesOptions = [
      {label: "Puntos para Eventos", value: "event_points"},
      {label: "Puntos para Tarjetas", value: "card_points"},
      {label: "Puntos para Regalos", value: "gift_points"},
      {label: "Puntos para Monederos", value: "wallet_points"},
    ];

    this.actionsOptions = [
      {label: "Otorgar Puntos", value: "add"},
      {label: "Quitar Puntos", value: "subtract"}
    ];

    this.form = {
        action: '',
        type: '',
        points: '',
        description: ''
    }
  },
  methods: {
    updatePointsModal  (supplier) {
      this.showModal = true;
      this.color = "info";
      this.title = 'Puntos de los Participantes';
      this.isEdit = true;
      this.submitted = false;

      this.form = {
        id: supplier.id,
        action: 'add',
        type: 'event_points',
        points: '',
        description: ''
      };
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        this.$emit("upoints", this.form );

        this.closeModal();
      }
    },
    closeModal () {
      this.showModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
        id: 0,
        action: '',
        type: '',
        points: '',
        description: ''
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
    redirectLink(link){
      window.location = link;
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      id: {
        required
      },
      action: {
        required
      },
      type: {
        required
      },
      points: {
        required
      },
      description: {
        required
      }
    }
  },
}
</script>