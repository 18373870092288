<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-https"/> {{caption}}
        <div class="card-header-actions">
          <CButton color="success" size="sm" @click="exportData()">Exportar a Excel</CButton>
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        :tableFilter="tableFilter"
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="30"
        :dark="dark"
        pagination
      >
        <template #code="{item}"><td class="text-center w150">{{item.code}}</td></template>
        <template #event_points="{item}"><td class="text-center w150">{{item.event_points}}</td></template>
        <template #card_points="{item}"><td class="text-center w150">{{item.card_points}}</td></template>
        <template #gift_points="{item}"><td class="text-center w150">{{item.gift_points}}</td></template>
        <template #wallet_points="{item}"><td class="text-center w150">{{item.wallet_points}}</td></template>
        <template #actions="{item}">
          <td class="table_actions b2">
            <CButton color="info" size="sm" @click="updatePointsModal(item)">Modificar</CButton>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
    <PointsModal ref="pointsModal" @upoints="updateProcess"></PointsModal>
  </CCard>
</template>

<script>
import ws from '../../services/suppliers';
import store from '../../store'
import router from '../../router/index'
import PointsModal from './PointsModal.vue'

export default {
  name: 'PointsTable',
  components: { PointsModal },
  props: {
    items: Array,
    permissions: Object,
    fields: {
      type: Array,
      default () {
        return [
          {key: 'code', label: 'Ruta'},
          {key: 'name', label: 'Nombre'},
          {key: 'event_points', label: 'Eventos'},
          {key: 'card_points', label: 'Tarjeta'},
          {key: 'gift_points', label: 'Regalo'},
          {key: 'wallet_points', label: 'Monedero'},
          {key: 'actions', label: ''}
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data() {
    return {
      toasts: [],
      tableFilter: {
        label: 'Buscar: ',
        placeholder: '...'
      }
    }
  },
  methods: {
    async exportData(){
      this.loading();

      let response = await ws.getExcelPoints();

      if(response.type == "success"){
        window.location = response.data;
      }
      
      this.loaded();
    },
    updatePointsModal (supplier_id) {
      this.$refs.pointsModal.updatePointsModal(supplier_id);
    },
    async updateProcess(data) {
      let response = await ws.updatePoints(data);

      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
    slugify(str) {
      return String(str)
        .normalize('NFKD') // split accented characters into their base characters and diacritical marks
        .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
        .trim() // trim leading or trailing whitespace
        .toLowerCase() // convert to lowercase
        .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
        .replace(/\s+/g, '-') // replace spaces with hyphens
        .replace(/-+/g, '-'); // remove consecutive hyphens
    }
  }
}
</script>
